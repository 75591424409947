<template>
  <section v-can="'RepMedExt1'">
    <b-modal
      id="onlending-extract-modal"
      hide-header
      hide-footer
      centered
      size="xl"
      @hidden="closeModal"
      @show="getExtractById"
    >
      <div class="modal-header">
        <div class="modal-title">Extrato do repasse médico</div>
        <span class="icon-box">
          <Close class="icon stroke" @click="closeModal" />
        </span>
      </div>

      <b-container class="py-3 px-4">
        <b-row>
          <b-col cols="2">
            <p class="label">Favorecido</p>
            <p class="info">{{ extract?.beneficiary?.name }}</p>
          </b-col>

          <b-col cols="2">
            <p class="label">Extrato</p>
            <p class="info">Nº {{extract?.number}} </p>
          </b-col>

          <b-col cols="2">
            <p class="label">Data inicial</p>
            <p class="info">{{moment(extract?.report?.period_start).format('DD/MM/YYYY')}}</p>
          </b-col>

          <b-col cols="2">
            <p class="label">Data final</p>
            <p class="info">{{moment(extract?.report?.period_end).format('DD/MM/YYYY')}}</p>
          </b-col>

          <b-col cols="2">
            <b-dropdown
              v-if="!['A pagar', 'Finalizado', 'Excluído'].includes(extract?.status)"
              v-can="'RepMedExt3'"
              :text="`Status: ${extract?.status}`"
              variant="none"
              class="ml-auto mt-1"
              :class="getClass(extract?.status)" 
            >
              <b-dropdown-item @click="updateStatus(extract.id, 'Conferido')">
                Conferido
              </b-dropdown-item>
              <b-dropdown-item @click="updateStatus(extract.id, 'Enviado')">
                Enviado
              </b-dropdown-item>
              <b-dropdown-item @click="updateStatus(extract.id, 'A revisar')">
                A revisar
              </b-dropdown-item>
            </b-dropdown>

            <b-button 
              v-else
              :variant="extract?.status === 'A pagar' ? 'primary' : (extract?.status === 'Finalizado' ? 'secondary': 'dark')"
            >
              {{extract?.status}}
            </b-button>
          </b-col>

          <b-col 
            cols="2"
            class="text-right"
            v-if="extract?.bill?.id"
          >
            <p class="label mb-0">Conta a pagar</p>
            <b-button
              variant="link"
              @click="openBillToPayModal"
            >
              Visualizar
            </b-button>         
         </b-col>
        </b-row>
        
        <hr class="my-4"/>

        <Filters 
          @changeFilters="filterOnlendings"
          :key="reloadFilters"
        />
        
        <ItemsTable
          :refreshTables="refreshTables"
          :extract="extract"
          :updateOnledingStatus="updateOnlending"
          type="review"
          :currentPage="reviewPage"
          @changePage="changePage"
          @refreshAllTables="refreshAllTables"
          :filters="filters"
          @removeOnlendingFromExtract="(val) => $emit('removeOnlendingFromExtract',val)"
        />

        <ItemsTable
          :refreshTables="refreshTables"
          :extract="extract"
          :updateOnledingStatus="updateOnlending"
          type="checked"
          :currentPage="checkedPage"
          @changePage="changePage"
          @refreshAllTables="refreshAllTables"
          :filters="filters"
          @removeOnlendingFromExtract="(val) => $emit('removeOnlendingFromExtract',val)"
        />
      </b-container>  
        
      <b-row class="d-flex justify-content-end mr-4 mb-2">
        <b-button
          class="mr-2"
          variant="outline-primary"
          @click="closeModal"
        >
          Voltar
        </b-button>
        <b-button
          v-if="['Conferido', 'Enviado', 'A pagar'].includes(extract?.status) && !extract?.bill?.id"
          class="mr-2"
          variant="primary"
          @click="openBillToPayModal"
        >
          Gerar conta a pagar
        </b-button>
      </b-row>
    </b-modal>

    <BillsToPayModal
      :billToPayId="billId"
      :onlendingExtract="!extract?.bill?.id ? extract : null"
      @createBill="createBill"
    />
  </section>
</template>

<script>
import api from '@/modules/onlending/api'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '../../../../utils/localStorageManager'

export default {
  name: 'OnlendingExtractModal',
  components: {
    BillsToPayModal: () => import('@/components/Financial/BillsToPayModal'),
    Close: () => import('@/assets/icons/close.svg'),
    Filters: () => import('@/modules/onlending/components/extract/ModalFilters.vue'),
    ItemsTable: () => import('@/modules/onlending/components/extract/ItemsTable')
  },
  props: {
    extractId: String,
    updateStatus: Function,
    updateOnledingStatus: Function,
    updateOnledingExtract: Function,
    refreshModal: Number
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      extract: null,

      reviewPage: 1,
      checkedPage: 1,

      refreshTables: 1,
      reloadFilters: 1
    }
  },
  computed: {
    billId() {
      return this.extract?.bill?.id
    }
  },
  methods: {
    parseNumberToMoney,
    async getExtractById() {
      const isLoading = this.$loading.show()
      const data = {
        clinic_id: this.clinic.id,
        id: this.extractId
      }
      await api.getOnlendingExtractsByExtractsId(data)
      .then(res => {  
        this.extract = res.data
      })
      .catch(error => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },

    filterOnlendings(filters){
      this.filters = filters
      this.checkedPage = 1
      this.reviewPage = 1
      this.refreshAllTables()
    },

    changePage(data){
      if(data.type === 'checked'){
        this.checkedPage = data.page
      }
      else if(data.type === 'review'){
        this.reviewPage = data.page
      }      
    },

    closeModal() {
      this.extract = null
      this.filters = null
      this.reloadFilters++
      this.$emit('closeModal')
      this.$bvModal.hide('onlending-extract-modal')
    },
    openBillToPayModal() {
      this.$bvModal.show('bill-to-pay-modal')
    },
    createBill(data){
      let status = 'A pagar'
      let allPayed = true
      data.payments.forEach(payment => {
        payment.installments.forEach(installment => {
          if(installment.status !== 'paid_out') allPayed = false
        });
      });
      if(allPayed) status = 'Finalizado'
      this.updateOnledingExtract(this.extract?.id, {
        bill_id: data.id,
        status: status
      })
      this.closeModal()
    },
    refreshAllTables(){
      this.checkedPage = 1
      this.reviewPage = 1 
      this.refreshTables++
    },
    getClass(status){
      switch(status) {
        case 'A conferir': return 'red'
        case 'A revisar': return 'purple'
        case 'Conferido': return 'green'
        case 'Enviado': return 'yellow'
      }
    },
    updateOnlending(ids, status){
      this.updateOnledingStatus(ids, status)
    }
  },
  watch: {
    refreshModal() {      
      this.getExtractById()
      this.refreshAllTables()
    }
  }
}
</script>

<style lang="scss">
#onlending-extract-modal {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-body {
      padding: 0;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--type-active);

      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 24px;

          .close-svg {
            height: 24px;
            width: 24px;
            fill: var(--neutral-500);
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .body-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 50%;

          p {
            margin: 40px 0;
          }

          .batch-number {
            font-weight: bold;
            color: var(--blue-500);
          }

          .warning-svg {
            height: 96px;
            width: 96px;
            rect:first-child {
              fill: var(--blue-100);
            }

            rect:nth-child(2) {
              fill: var(--blue-500);
            }
          }

          .buttons {
            display: flex;
            flex-direction: row;

            .button {
              width: 168px;
              height: 40px;
              &:last-child {
                margin-left: 2%;
              }
            }

            .outline {
              margin: 0 5px;
              color: var(--neutral-000);
            }
          }
        }
      }
    }
  }

  .footer-text{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    color: var(--type-active);
  }

  .label{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: #0C1D59;
    margin-bottom: 6px;
  }

  .info{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: #525C7A;
  }

  .card-title{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 134%;
    letter-spacing: -0.01em;
    color: var(--dark-blue);
  }

  table th, .table td {
    padding: 0.75rem 0;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    /* border: none; */
  }

  .tr-header {
    th {
      padding: 16px 8px;
    }

    .table th, .table td {
      padding: 0;
      vertical-align: middle;
      border-top: none;
    }

    td {
      padding: 16px 8px !important;
    }
  }

  th, td {
    text-align: left;
  }

  th {
    font-size: 14px;
    color: var(--dark-blue);
  }

  td{
    font-size: 15px;
    color: var(--type-active);
    padding: 0.75rem !important;
  }

  
  .result-label{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
    padding: 8px 16px;
    height: 40px;
    background: var(--blue-100);
    border-radius: 8px;

    b{
      font-weight: 700;
      color: #305BF2;
    }
  }

  .icon {
    width: 25px;
    fill: var(--neutral-500);
  }
}
.red{
  background-color: #FED2CE;
}
.purple{
  background-color: #E2CCFF;
}
.green{
  background-color: #E7FFDB;
}
.yellow{
  background-color: #FFEBCC;
}
.gray{
  background-color: #E9EBF2;
}
</style>